import { CommonModule } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { BasicServerError, ServerError } from "app/common/error";
import { isBreezeSaveError, isBreezeServerError } from "app/dal/breeze-error-guards";

@Component({
    standalone: true,
    selector: "app-save-error",
    template: `
        <mat-expansion-panel
            [class.expansion-disabled]="details.length === 0"
            [hideToggle]="details.length === 0"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon>error</mat-icon>
                    {{ errorText }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ul>
                <li *ngFor="let d of details">{{ d }}</li>
            </ul>
        </mat-expansion-panel>
    `,
    styles: [
        `
            mat-expansion-panel {
                --mat-expansion-container-background-color: #f44336; /* TODO this is mat-warn hard coded */
                --mat-expansion-container-text-color: white;
                --mat-expansion-header-text-color: white;
                --mat-expansion-header-indicator-color: white;
            }

            .expansion-disabled {
                pointer-events: none;
            }

            ul {
                margin: 0;
            }

            mat-icon {
                margin-right: 0.5rem;
            }
        `,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatExpansionModule,
    ],
})
export class SaveErrorComponent implements OnChanges {
    @Input() saveError!: Error;
    public errorText?: string;
    public details: string[] = [];

    public ngOnChanges() {
        this.errorText = this.generateErrorText();
        this.details = this.getErrorDetails();
    }

    private generateErrorText() {
        if (isBreezeSaveError(this.saveError)) {
            const nonPropertyErrors = this.saveError.entityErrors.filter((e) => !e.propertyName);
            if (nonPropertyErrors.length > 0) {
                const entityErrorText = nonPropertyErrors.map((e) => e.errorMessage).join(", ");
                return `Invalid data entered: ${entityErrorText}. Please correct it and try again.`;
            } else {
                return `Invalid data entered, please correct it and try again.`;
            }
        }

        if (isBreezeServerError(this.saveError)) {
            if (this.saveError.status === 400) {
                return `Invalid data provided for this action. This is likely a bug and has been logged.`;
            } else if (this.saveError.status === 403) {
                return `You don't have permission to perform this action, please get in contact with your administrator`;
            } else if (this.saveError.status === 0) {
                return `Unable to save changes, are you connected to the internet?`;
            }
        }

        if (this.saveError instanceof ServerError) {
            return this.saveError.message;
        }

        return "Unknown error, please refresh and try again";
    }

    private getErrorDetails() {
        if (this.saveError instanceof BasicServerError) {
            return this.saveError.errors.map((e) => e.description);
        } else {
            return [];
        }
    }
}
