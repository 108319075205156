import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import { User } from "app/auth/user";
import { UserObserver } from "app/auth/user-observer";
import { EMPTY, Observable, of, Subscription } from "rxjs";
import { filter, first, map, shareReplay, switchMap } from "rxjs/operators";
import { DialogObserver } from "./common-ux/dialog/dialog-observer";
import { AppConfig } from "./common/app-config";
import { isLeafRoute, primaryOutletName } from "./common/routing-utils";

interface Heap {
    load(appId: string): void;
    identify(identifier: string): void;
    resetIdentity(): void;
    addUserProperties(properties: Record<string, string>): void;
    track(name: string, properties: Record<string, string | number>): void;
}

@Injectable({
    providedIn: "root",
})
export class HeapAnalytics implements UserObserver, DialogObserver, OnDestroy {
    private _heapEnabled$: Observable<boolean>;
    private subscription: Subscription;

    public constructor(appConfig: AppConfig, router: Router) {
        this._heapEnabled$ = appConfig.heapAppId$.pipe(
            map((appId) => {
                if (appId) {
                    this.heap.load(appId);
                }

                return !!appId;
            }),
            shareReplay(1),
        );

        const lastOutletPath: Record<string, string> = {};
        this.subscription = this.emitIfHeapEnabled$
            .pipe(
                switchMap(() => router.events),
                filter((e): e is ActivationEnd => e instanceof ActivationEnd),
                filter((e) => isLeafRoute(e.snapshot)),
            )
            .subscribe((e: ActivationEnd) => {
                const genericPathParts: string[] = [];

                let snapshot: ActivatedRouteSnapshot | null = e.snapshot;
                while (snapshot && snapshot.outlet === primaryOutletName) {
                    if (snapshot.routeConfig?.path) {
                        genericPathParts.push(snapshot.routeConfig.path);
                    }
                    snapshot = snapshot.parent;
                }

                if (snapshot && snapshot.outlet !== primaryOutletName) {
                    let outletPart = `(${snapshot.outlet}:`;
                    if (snapshot.routeConfig?.path) {
                        outletPart += snapshot.routeConfig.path;
                    }
                    genericPathParts.push(outletPart);
                    genericPathParts[0] = `${genericPathParts[0] ?? ""})`;
                }

                const outlet = snapshot?.outlet ?? "primary";
                const fullGenericPath = genericPathParts.reverse().join("/");

                if (lastOutletPath[outlet] !== fullGenericPath) {
                    lastOutletPath[outlet] = fullGenericPath;
                    this.heap.track("RouteActivated", {
                        routeConfigPath: fullGenericPath,
                    });
                }
            });
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // Only access this if you have verified via this.emitIfHeapEnabled$
    private get heap() {
        return (window as any).heap as Heap;
    }

    private get emitIfHeapEnabled$() {
        return this._heapEnabled$.pipe(
            first(),
            switchMap((isEnabled) => (isEnabled ? of({}) : EMPTY)),
            map(() => this.heap),
        );
    }

    public onUserChanged(user: User | undefined) {
        this.emitIfHeapEnabled$.subscribe(() => {
            if (user) {
                this.heap.identify(user.id);
                this.heap.addUserProperties({
                    username: user.username,
                });
            } else {
                this.heap.resetIdentity();
            }
        });
    }

    public onDialogOpened(dialogName: string, _inputData: unknown) {
        this.emitIfHeapEnabled$.subscribe(() => {
            this.heap.track("DialogOpened", {
                dialogName,
            });
        });
    }

    public onDialogClosed(_dialogName: string, _resolveData: unknown) {
        // Noop
    }

    public onDialogCancelled(_dialogName: string) {
        // Noop
    }
}
