import { BreezeEntity } from "./breeze-entity";
import { BreezeModel } from "./breeze-model";
import { AssetBreezeModel } from "./model/asset";
import { AssetLoanBreezeModel } from "./model/asset-loan";
import { ConcertBreezeModel } from "./model/concert";
import {
    EnsembleBreezeModel,
    EnsembleMembershipBreezeModel,
    EnsembleRoleBreezeModel,
} from "./model/ensemble";
import {
    MemberBreezeModel,
    MemberDetailsBreezeModel,
    MemberInstrumentBreezeModel,
} from "./model/member";
import { MemberBillingConfigBreezeModel } from "./model/member-billing-config";
import { MemberBillingPeriodBreezeModel } from "./model/member-billing-period";
import { MemberBillingPeriodConfigBreezeModel } from "./model/member-billing-period-config";
import { MemberInvoiceBreezeModel, MemberInvoiceLineItemBreezeModel } from "./model/member-invoice";
import {
    PerformanceBreezeModel,
    PerformanceMemberBreezeModel,
    PerformanceScoreBreezeModel,
} from "./model/performance";
import { RoleBreezeModel } from "./model/role";
import { ScoreAttachmentBreezeModel, ScoreBreezeModel } from "./model/score";
import { TenantBreezeModel } from "./model/tenant";

export const breezeModelList: readonly BreezeModel<BreezeEntity>[] = [
    TenantBreezeModel,

    AssetBreezeModel,
    AssetLoanBreezeModel,

    EnsembleBreezeModel,
    EnsembleMembershipBreezeModel,
    EnsembleRoleBreezeModel,

    MemberBillingConfigBreezeModel,
    MemberBillingPeriodConfigBreezeModel,
    MemberBillingPeriodBreezeModel,
    MemberInvoiceBreezeModel,
    MemberInvoiceLineItemBreezeModel,

    ScoreBreezeModel,
    ScoreAttachmentBreezeModel,

    MemberBreezeModel,
    MemberDetailsBreezeModel,
    MemberInstrumentBreezeModel,

    ConcertBreezeModel,
    PerformanceBreezeModel,
    PerformanceMemberBreezeModel,
    PerformanceScoreBreezeModel,

    RoleBreezeModel,
];
