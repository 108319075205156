import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";
import { Member } from "./member";
import { MemberBillingPeriod } from "./member-billing-period";

export enum MemberInvoiceSendStatus {
    Pending = "Pending",
    Delivered = "Delivered",
    Opened = "Opened",
    Failed = "Failed",
}

interface Metadata {
    icon: string;
    description: string;
}

export const MemberInvoiceSendStatusMetadata: Record<MemberInvoiceSendStatus, Metadata> = {
    [MemberInvoiceSendStatus.Pending]: {
        icon: "schedule_send",
        description: "Email has been sent and is pending delivery",
    },
    [MemberInvoiceSendStatus.Delivered]: {
        icon: "email",
        description: "Email has been successfully delivered",
    },
    [MemberInvoiceSendStatus.Opened]: {
        icon: "drafts",
        description: "Email has been opened",
    },
    [MemberInvoiceSendStatus.Failed]: {
        icon: "error",
        description: "There was an error delivering this email",
    },
};

export class MemberInvoice extends BreezeEntity {
    id!: number;
    memberBillingPeriodId!: number;
    memberId!: number;
    isConcession!: boolean;
    reference!: string;
    sent!: Date | null;
    sendStatus!: MemberInvoiceSendStatus | null;
    sendStatusUpdated!: Date | null;
    sendStatusMessage!: string | null;
    paid!: Date | null;
    notes!: string | null;

    memberBillingPeriod!: MemberBillingPeriod | null;
    member!: Member | null;
    lineItems!: MemberInvoiceLineItem[];

    public get total() {
        return this.lineItems.reduce((p, c) => p + c.amountCents, 0) / 100;
    }
}

export class MemberInvoiceLineItem extends BreezeEntity {
    id!: number;
    memberInvoiceId!: number;
    description!: string;
    amountCents!: number;
    ordinal!: number;

    memberInvoice!: MemberInvoice;
}

export const MemberInvoiceBreezeModel: BreezeModel<MemberInvoice> = {
    typeName: "MemberInvoice",
    type: MemberInvoice,
};

export const MemberInvoiceLineItemBreezeModel: BreezeModel<MemberInvoiceLineItem> = {
    typeName: "MemberInvoiceLineItem",
    type: MemberInvoiceLineItem,
};
