import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AppButtonModule } from "app/common-ux/button/button.module";
import { AppCommonUxModule } from "app/common-ux/common-ux.module";
import { AppAuthRoutingModule } from "./auth-routing.module";
import { AutoDateConversionInterceptor } from "./auto-date-conversion.interceptor";
import { BearerTokenInterceptor } from "./bearer-token.interceptor";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { IfHasPermissionDirective } from "./if-has-permission/if-has-permission.directive";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SessionTimeoutInterceptor } from "./session-timeout-interceptor";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,

        AppAuthRoutingModule,
        AppCommonUxModule,
        AppButtonModule,
    ],
    exports: [
        IfHasPermissionDirective,
    ],
    declarations: [
        LoginComponent,
        IfHasPermissionDirective,
        ForgotPasswordComponent,
        ResetPasswordComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionTimeoutInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AutoDateConversionInterceptor,
            multi: true,
        },
    ],
})
export class AppAuthModule {}
