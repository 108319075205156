import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { convertObjectDateStringsToDate } from "app/common/utils";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AutoDateConversionInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event) => {
                if (event instanceof HttpResponse) {
                    event = event.clone({
                        body: convertObjectDateStringsToDate(event.body),
                    });
                }

                return event;
            }),
        );
    }
}
