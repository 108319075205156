import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthenticationService } from "app/auth/authentication.service";
import { TenantService } from "app/tenant/module/tenant.service";

interface LoginParams extends Params {
    failMessage?: string;
}

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    public loginState = {
        isInvalid: false,
        isLoggingIn: false,
        failMessage: "",
    };
    public username = "";
    public password = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private tenantService: TenantService,
    ) {}

    public ngOnInit() {
        const params: LoginParams = this.route.snapshot.params;

        if (params.failMessage) {
            this.loginState.isInvalid = true;
            this.loginState.failMessage = params.failMessage;
        }
    }

    public async loginOnClick() {
        this.loginState.isLoggingIn = true;

        try {
            const user = await this.authenticationService.login(this.username, this.password);

            const redirect = this.route.snapshot.params.redirect;
            if (redirect) {
                await this.router.navigateByUrl(redirect);
            } else {
                const url = this.tenantService.getDefaultUrlForUser(user);
                await this.router.navigateByUrl(url);
            }
        } catch (e) {
            this.loginState.isInvalid = true;
            this.loginState.isLoggingIn = false;
            this.loginState.failMessage = (e instanceof Error && e.message) || "Unknown error";
        }
    }
}
