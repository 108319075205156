export interface ErrorResponse {
    type: string;
    message: string;
}

export class ServerError<T extends ErrorResponse> extends Error {
    public constructor(public readonly data: T) {
        super();
        this.name = data.type;
        this.message = data.message;
    }
}

export interface BasicErrorResponse extends ErrorResponse {
    type: "ErrorResponse";
    errors: {
        code: string;
        description: string;
    }[];
}

export class BasicServerError extends ServerError<BasicErrorResponse> {
    public static matchingResponse(response: ErrorResponse): response is BasicErrorResponse {
        return response.type === "ErrorResponse";
    }

    public get errors() {
        return this.data.errors;
    }
}

export function isErrorResponse(error: unknown): error is ErrorResponse {
    return (
        typeof error === "object" &&
        error != null &&
        typeof (error as any).type === "string" &&
        typeof (error as any).message === "string"
    );
}

export function getErrorMessage(error: unknown, unknownMessage = "Unknown error") {
    if (error instanceof Error) {
        return error.message;
    } else {
        return unknownMessage;
    }
}
