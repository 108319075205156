import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { of } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { AuthenticationService } from "../../auth/authentication.service";
import { TenantService } from "./tenant.service";

export const hasAccessToTenantGuard: CanActivateFn = (route, state) => {
    const tenantService = inject(TenantService);

    const tenantAbbreviation = tenantService.getTenantAbbreviationForRoute(route);
    const accessibleTenantRedirection = getAccessibleTenantRedirectionFn();
    if (!tenantAbbreviation) {
        return accessibleTenantRedirection(state.url);
    }

    return tenantService.getTenantByAbbreviation(tenantAbbreviation).pipe(
        switchMap((tenant) => {
            if (tenant) {
                return of(true);
            } else {
                return accessibleTenantRedirection(state.url);
            }
        }),
    );
};

function getAccessibleTenantRedirectionFn() {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const tenantService = inject(TenantService);

    return (originalTarget: string) =>
        authenticationService.user$.pipe(
            first(),
            map((user) => {
                if (user) {
                    // TODO Notify?
                    return tenantService.getDefaultUrlForUser(user);
                } else {
                    return router.createUrlTree([
                        "/login",
                        {
                            redirect: originalTarget,
                        },
                    ]);
                }
            }),
        );
}
