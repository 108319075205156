import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";

export class MemberBillingPeriodConfig extends BreezeEntity {
    public id!: number;
    public name!: string;
    public autoGenerateMonth!: number;
    public endOfPeriodMonth!: number;
}

export const MemberBillingPeriodConfigBreezeModel: BreezeModel<MemberBillingPeriodConfig> = {
    typeName: "MemberBillingPeriodConfig",
    type: MemberBillingPeriodConfig,
};
