import { Injectable } from "@angular/core";
import { FeaturePermission } from "app/auth/feature-permission";
import { Roles } from "app/auth/user";
import { TenantService } from "app/tenant/module/tenant.service";
import { combineLatest, map, Observable } from "rxjs";
import { AuthenticationService } from "../../auth/authentication.service";

@Injectable()
export class TenantAuthorisationService {
    public readonly tenantRoles$: Observable<Roles>;

    public constructor(authenticationService: AuthenticationService, tenantService: TenantService) {
        this.tenantRoles$ = combineLatest([
            authenticationService.user$,
            tenantService.currentTenant$,
        ]).pipe(
            map(([user, tenant]) => {
                return user && tenant
                    ? user.getImpliedTenantRolesByAbbreviation(tenant.abbreviation)
                    : new Roles([]);
            }),
        );
    }

    public hasPermission(permission: FeaturePermission) {
        return this.tenantRoles$.pipe(map((roles) => roles.hasPermission(permission)));
    }
}
