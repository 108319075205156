import { MatDialogRef } from "@angular/material/dialog";
import { filter, merge, Observable } from "rxjs";

export abstract class BaseDialog<TInput, TResolve = TInput> {
    public static readonly cancelValue = new Object();

    public abstract readonly dialogName: string;

    /**
     * Emits when the dialog is cancelled by pressing escape or clicking on the backdrop.
     * Use this to perform cleanup when this occurs.
     */
    protected dialogClose$: Observable<KeyboardEvent | MouseEvent>;

    public constructor(protected dialogRef: MatDialogRef<BaseDialog<TInput, TResolve>>) {
        this.cancel = this.cancel.bind(this);
        this.resolve = this.resolve.bind(this);

        this.dialogClose$ = merge(
            dialogRef.keydownEvents().pipe(filter((e) => e.key === "Escape")),
            dialogRef.backdropClick(),
        ).pipe(filter(() => !dialogRef.disableClose));

        // Ensure dialog closes as a cancel so dialog doesn't emit with
        // undefined which makes it not detected as a cancel
        this.dialogClose$.subscribe(() => {
            this.cancel();
        });
    }

    /** Close the dialog, not resolving with any value */
    public cancel() {
        this.dialogRef.close(BaseDialog.cancelValue);
    }

    /** Close the dialog, resolving with the given value */
    public resolve(value: TResolve) {
        this.dialogRef.close(value);
    }
}
