import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { loggedInGuard } from "app/auth/logged-in.guard";
import { hasAccessToTenantGuard } from "./has-access-to-tenant.guard";
import { SelectTenantPageComponent } from "./select-tenant-page/select-tenant-page.component";

export const tenantAbbreviationParam = "tenantAbbreviation";

const tenantRoutes: Routes = [
    {
        path: "group",
        children: [
            {
                path: "",
                component: SelectTenantPageComponent,
                canActivate: [
                    loggedInGuard,
                ],
                data: {
                    title: "Select group",
                },
            },
            {
                path: `:${tenantAbbreviationParam}`,
                canActivate: [
                    loggedInGuard,
                    hasAccessToTenantGuard,
                ],
                loadChildren: () =>
                    import("app/tenant/shell/shell.module").then((m) => m.AppShellModule),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(tenantRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppTenantRoutingModule {}
