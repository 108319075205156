import { MediaMatcher } from "@angular/cdk/layout";
import { Injectable, NgZone } from "@angular/core";
import { runInZone } from "app/common/rxjs-utilities";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, startWith } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ResponsiveService {
    private static readonly desktopMinWidth = 600;

    private desktopQuery: MediaQueryList;
    private xlQuery: MediaQueryList;

    public constructor(
        private media: MediaMatcher,
        private ngZone: NgZone,
    ) {
        this.desktopQuery = this.getSizeQuery(ResponsiveService.desktopMinWidth);
        this.xlQuery = this.getSizeQuery(1200);
    }

    public get isMobile$() {
        return this.fromMediaQueryList(this.desktopQuery).pipe(map((isDesktop) => !isDesktop));
    }

    public get isXl$() {
        return this.fromMediaQueryList(this.xlQuery);
    }

    private fromMediaQueryList(mediaQueryList: MediaQueryList) {
        return fromMediaQueryList(mediaQueryList).pipe(runInZone(this.ngZone));
    }

    private getSizeQuery(minPixels: number) {
        return this.media.matchMedia(`(min-width: ${minPixels}px)`);
    }
}

function fromMediaQueryList(mediaQueryList: MediaQueryList) {
    return new Observable<boolean>((subscriber) => {
        const mobileQueryListener = () => subscriber.next(mediaQueryList.matches);

        // To support Safari < 14, we have to check for this
        // See https://caniuse.com/mdn-api_mediaquerylist
        if (typeof mediaQueryList.addEventListener === "function") {
            mediaQueryList.addEventListener("change", mobileQueryListener);
        } else {
            mediaQueryList.addListener(mobileQueryListener);
        }

        return () => {
            if (typeof mediaQueryList.removeEventListener === "function") {
                mediaQueryList.removeEventListener("change", mobileQueryListener);
            } else {
                mediaQueryList.removeListener(mobileQueryListener);
            }
        };
    }).pipe(startWith(mediaQueryList.matches), distinctUntilChanged());
}
