import { formatDate } from "@angular/common";

export function deleteArrayElement<T>(array: T[], element: T) {
    const index = array.indexOf(element);

    if (index > -1) {
        array.splice(index, 1);
    }
}

export function filterExcept<T>(array: T[], except: T[]) {
    return array.filter((e) => !except.includes(e));
}

export function getArrayDifference<T>(original: T[], updated: T[]) {
    return {
        added: updated.filter((n) => !original.includes(n)),
        removed: original.filter((o) => !updated.includes(o)),
    };
}

export function areSameArray<T>(a: T[] = [], b: T[] = []) {
    const currSet = new Set(a);
    const prevSet = new Set(b);

    if (currSet.size !== prevSet.size) {
        return false;
    }

    return Array.from(currSet).every((v) => prevSet.has(v));
}

export function isArrayWithData(val: unknown) {
    return Array.isArray(val) && val.length > 0;
}

export function filterOutNullOrUndefined<T>(a: (T | undefined | null)[]): T[] {
    return a.filter((v): v is T => v !== undefined && v !== null);
}

/** Create a date with a *1* base indexed month */
export function saneDate(year: number, month: number, day: number) {
    return new Date(year, month - 1, day);
}

export function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function formatLongDate(date: Date) {
    return formatDate(date, "d MMMM yyyy", navigator.language);
}

export function formatMonthYearDate(date: Date) {
    return formatDate(date, "MMM yyyy", navigator.language);
}

export function padInteger(number: number, size: number) {
    let paddedInt = String(number);
    while (paddedInt.length < size) {
        paddedInt = "0" + paddedInt;
    }

    return paddedInt;
}

export function valueIfConditionMet<T>(condition: boolean, val: T) {
    return condition ? val : undefined;
}

/**
 * Convert all string properties in the form of an ISO8601 date
 * in an object to full Date object.
 * Code adapted from:
 * http://aboutcode.net/2013/07/27/json-date-parsing-angularjs.html
 */
export function convertObjectDateStringsToDate(inObject: object | any[]): any {
    if (canConvertObject(inObject)) {
        recurseConvertObjectDates(inObject);
    }

    return inObject;
}

// Regex borrowed from Stack Overflow:
// http://stackoverflow.com/a/37563868
const iso8601Regex = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:?\d\d)|Z)?$/;
const dateOnlyRegex = /^(\d{4})-(\d\d)-(\d\d)$/;

function recurseConvertObjectDates(obj: object | any[]) {
    /* For each property, check for the regex string and convert,
     * or recurse if it is an object
     */
    Object.keys(obj).forEach((key) => {
        const value = (obj as any)[key];

        if (typeof value === "string" && iso8601Regex.test(value)) {
            (obj as any)[key] = new Date(value);
        } else if (typeof value === "string" && dateOnlyRegex.test(value)) {
            // Doing it this way makes it midnight in the local timezone
            // Otherwise, its midnight at UTC
            const matches = dateOnlyRegex.exec(value)!;
            (obj as any)[key] = saneDate(
                Number(matches[1]),
                Number(matches[2]),
                Number(matches[3]),
            );
        } else if (canConvertObject(value)) {
            recurseConvertObjectDates(value);
        }
    });
}

function canConvertObject(obj: any) {
    return !!obj && (typeof obj === "object" || Array.isArray(obj));
}

export function generatePatchDocument<T>(entity: Partial<T>) {
    return Object.entries(entity).map(([key, value]) => {
        return {
            op: "replace",
            path: `/${key}`,
            value,
        };
    });
}

export function groupBy<T, K>(values: T[], selector: (t: T) => K) {
    const groups = new Map<K, T[]>();

    for (const v of values) {
        const key = selector(v);

        if (!groups.has(key)) {
            groups.set(key, []);
        }

        groups.get(key)!.push(v);
    }

    return Array.from(groups.entries());
}

// Adapted from https://stackoverflow.com/a/18650828/17403538
export function formatBytes(bytes: number, decimals = 1) {
    if (bytes === 0) {
        return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
        "Bytes",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i] ?? ""}`;
}

export function findElementByRef<T extends HTMLElement = HTMLElement>(ref: string) {
    return document.querySelector<T>(`[data-ref=${ref}]`);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function voidFunction() {}

export function shallowMergeObjects<T extends object>(dst: T, ...src: T[]) {
    for (const s of src) {
        for (const [property, value] of Object.entries(s)) {
            dst[property as keyof T] = value;
        }
    }
    return dst;
}
