import { Inject, Injectable, Optional, Type } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EMPTY, Observable, ObservableInput, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { BaseDialog } from "./base-dialog";
import {
    ConfirmationDialogComponent,
    ConfirmationDialogData,
} from "./confirmation-dialog.component";
import { DialogObserver, DIALOG_OBSERVERS } from "./dialog-observer";
import {
    NotificationSnackbarComponent,
    NotificationSnackbarData,
} from "./notification-snackbar.component";

@Injectable()
export class DialogService {
    constructor(
        private matDialogService: MatDialog,
        private matSnackbarService: MatSnackBar,
        @Optional() @Inject(DIALOG_OBSERVERS) private dialogObservers: DialogObserver[],
    ) {
        // https://github.com/angular/angular/issues/25395
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!this.dialogObservers) {
            this.dialogObservers = [];
        }
    }

    public open<TInput, TResolve>(
        dialog: Type<BaseDialog<TInput, TResolve>>,
        data: TInput,
        options?: MatDialogConfig<TInput>,
    ): Observable<TResolve> {
        const dialogRef = this.matDialogService.open(dialog, {
            data,
            width: "calc(100vw - 30px)",
            maxWidth: 600,
            ...options,
        });

        const dialogName = dialogRef.componentInstance.dialogName;
        this.dialogObservers.forEach((o) => o.onDialogOpened(dialogName, data));

        return dialogRef.afterClosed().pipe(
            switchMap((value) => {
                if (value === BaseDialog.cancelValue) {
                    this.dialogObservers.forEach((o) => o.onDialogCancelled(dialogName));
                    return EMPTY;
                } else {
                    this.dialogObservers.forEach((o) => o.onDialogClosed(dialogName, value));
                    return of(value as TResolve);
                }
            }),
        );
    }

    public openConfirmation<T = void>(msg: string, onConfirming?: () => ObservableInput<T>) {
        return this.open<ConfirmationDialogData<T>, T>(ConfirmationDialogComponent, {
            message: msg,
            onConfirming: onConfirming,
        });
    }

    public success(msg: string) {
        this.notify(msg, "success");
    }

    public danger(msg: string) {
        this.notify(msg, "error");
    }

    public notify(msg: string, type: "info" | "success" | "error" = "info") {
        const icon = {
            info: "info",
            success: "check",
            error: "error",
        };
        const data: NotificationSnackbarData = {
            icon: icon[type],
            text: msg,
        };
        this.matSnackbarService.openFromComponent(NotificationSnackbarComponent, {
            data,
            duration: 5000,
        });
    }
}
