import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    ParamMap,
    createUrlTreeFromSnapshot,
} from "@angular/router";

export const primaryOutletName = "primary";
export const helpOutletName = "help";

export function isLeafRoute(route: ActivatedRouteSnapshot) {
    return route.children.length === 0;
}

export function isOnPrimaryRouteTree(route: ActivatedRouteSnapshot) {
    let currentRoute: ActivatedRouteSnapshot | null = route;

    do {
        if (currentRoute.outlet !== primaryOutletName) {
            return false;
        }

        currentRoute = currentRoute.parent;
    } while (currentRoute);

    return true;
}

export function buildPrimaryUrlTree(
    routeSnapshot: ActivatedRouteSnapshot,
    primaryCommands: unknown[],
) {
    const snapshot = findShellRouteSnapshot(routeSnapshot);
    return createUrlTreeFromSnapshot(snapshot, [
        { outlets: { [primaryOutletName]: primaryCommands } },
    ]);
}

export function buildHelpUrlTree(
    routeSnapshot: ActivatedRouteSnapshot,
    helpCommands: unknown[] | null,
) {
    // Ensure we generate the help relative to the route which has the help outlet
    const snapshot = findShellRouteSnapshot(routeSnapshot);
    return createUrlTreeFromSnapshot(snapshot, [{ outlets: { [helpOutletName]: helpCommands } }]);
}

export function buildPrimaryAndHelpUrlTree(
    routeSnapshot: ActivatedRouteSnapshot,
    primaryCommands: unknown[],
    helpCommands: unknown[] | null,
) {
    const snapshot = findShellRouteSnapshot(routeSnapshot);
    return createUrlTreeFromSnapshot(snapshot, [
        {
            outlets: {
                [primaryOutletName]: primaryCommands,
                [helpOutletName]: helpCommands,
            },
        },
    ]);
}

export function findShellRouteSnapshot(routeSnapshot: ActivatedRouteSnapshot) {
    let snapshot: ActivatedRouteSnapshot | null = routeSnapshot;

    if (snapshot === snapshot.root) {
        while (snapshot && !snapshot.data.isShellRoute) {
            snapshot = snapshot.children.find((c) => c.outlet === primaryOutletName) ?? null;
        }
    } else {
        while (snapshot && !snapshot.data.isShellRoute) {
            snapshot = snapshot.parent ?? null;
        }
    }

    if (!snapshot) {
        console.error("Unable to find shell route snapshot");
        snapshot = routeSnapshot;
    }

    return snapshot;
}

export function getCurrentPrimaryRouteLeaf(route: ActivatedRoute) {
    let curr: ActivatedRoute;
    let next: ActivatedRoute | undefined = route.root;

    do {
        curr = next;
        next = curr.children.find((r) => r.outlet === primaryOutletName);
    } while (next);

    return curr;
}

export function numberRouteParamFunc(name: string) {
    return (params: ParamMap) => {
        const rawNum = params.get(name);
        if (!rawNum) {
            return undefined;
        }

        const parsedNum = Number(rawNum);
        if (isNaN(parsedNum)) {
            return undefined;
        }

        return parsedNum;
    };
}
