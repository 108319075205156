import { Validator } from "breeze-client";
import { ValidationMessageContext } from "breeze-client/src/validate";

interface ValidatorFactory {
    name: string;
    build(this: void, context: ValidationMessageContext): Validator;
}

export const customValidators: Validator[] = [];

export const customValidatorFactories: ValidatorFactory[] = [
    {
        name: "range",
        build(context: any) {
            if (typeof context.minimum !== "number" || typeof context.maximum !== "number") {
                throw new Error("Expected a minimum and maximum number");
            }

            return new Validator(
                "range",
                (v: any, ctx?: ValidationMessageContext) => ctx?.minimum <= v && v <= ctx?.maximum,
                {
                    ...context,
                    messageTemplate: "%displayName% must be between %minimum% and %maximum%",
                },
            );
        },
    },
];
