import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { TenantService } from "app/tenant/module/tenant.service";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

export const notLoggedInGuard: CanActivateFn = (route, state) => {
    const authenticationService = inject(AuthenticationService);
    const tenantService = inject(TenantService);

    return authenticationService.user$.pipe(
        map((user) => {
            if (!user) {
                return true;
            }

            return tenantService.getDefaultUrlForUser(user);
        }),
    );
};
