import { BreezeEntity } from "../breeze-entity";
import { Ensemble } from "./ensemble";
import { Member } from "./member";

export enum EnsembleMembershipType {
    Regular = "Regular",
    Special = "Special",
}

interface MembershipTypeMetadata {
    type: EnsembleMembershipType;
    label: string;
    icon: string;
    feesAreCharged: boolean;
}
export const EnsembleMembershipTypeMetadata: Record<
    EnsembleMembershipType,
    MembershipTypeMetadata
> = {
    [EnsembleMembershipType.Regular]: {
        type: EnsembleMembershipType.Regular,
        label: "Regular player",
        icon: "attach_money",
        feesAreCharged: true,
    },
    [EnsembleMembershipType.Special]: {
        type: EnsembleMembershipType.Special,
        label: "Special player",
        icon: "star",
        feesAreCharged: false,
    },
};
export const AllEnsembleMembershipTypeMetadata = Object.values(EnsembleMembershipTypeMetadata);

export class EnsembleMembership extends BreezeEntity {
    public id!: number;
    public memberId!: number;
    public ensembleId!: number;
    public dateJoined!: Date;
    public dateLeft!: Date | null;
    public type!: EnsembleMembershipType;

    public ensemble!: Ensemble | null;
    public member!: Member | null;

    public cycleType() {
        const metadataType = EnsembleMembershipTypeMetadata[this.type];
        const index = AllEnsembleMembershipTypeMetadata.indexOf(metadataType);

        if (index + 1 >= AllEnsembleMembershipTypeMetadata.length) {
            this.type = AllEnsembleMembershipTypeMetadata[0]!.type;
        } else {
            this.type = AllEnsembleMembershipTypeMetadata[index + 1]!.type;
        }
    }
}
