import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "app/auth/authentication.service";
import { Observable } from "rxjs";

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authenticationService.bearerToken) {
            return next.handle(req);
        }

        const requestHeaders = req.headers.set(
            "Authorization",
            `Bearer ${this.authenticationService.bearerToken}`,
        );
        const authorisedRequest = req.clone({
            headers: requestHeaders,
        });

        return next.handle(authorisedRequest);
    }
}
