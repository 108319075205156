import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { ObservableInput, from, of } from "rxjs";
import { MatButtonLoadingDirective } from "../button/button-loading.directive";
import { SaveErrorComponent } from "../save-cancel-buttons/save-error.component";
import { BaseDialog } from "./base-dialog";

export interface ConfirmationDialogData<T = void> {
    message: string;
    onConfirming?: () => ObservableInput<T>;
}

@Component({
    standalone: true,
    template: `
        <mat-dialog-content>
            {{ data.message }}
            <app-save-error
                *ngIf="error"
                [saveError]="error"
            />
        </mat-dialog-content>
        <mat-dialog-actions>
            <button
                mat-raised-button
                color="primary"
                [loading]="loading"
                (click)="confirm()"
            >
                <mat-icon>check</mat-icon>
                Yes
            </button>
            <button
                mat-button
                [disabled]="loading"
                (click)="cancel()"
            >
                <mat-icon>close</mat-icon>
                No
            </button>
        </mat-dialog-actions>
    `,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatButtonLoadingDirective,
        SaveErrorComponent,
    ],
})
export class ConfirmationDialogComponent<T> extends BaseDialog<ConfirmationDialogData<T>, T> {
    public readonly dialogName = "Confirmation";

    public loading = false;
    public error?: Error;

    public constructor(
        dialogRef: MatDialogRef<ConfirmationDialogComponent<T>>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData<T>,
    ) {
        super(dialogRef);
        // TODO Only do this when the callback is in progress
        dialogRef.disableClose = true;
    }

    public confirm() {
        this.loading = true;
        this.error = undefined;

        const callbackResult = this.data.onConfirming?.() ?? of(undefined);
        from(callbackResult).subscribe({
            next: (data) => {
                this.loading = false;
                this.resolve(data as T);
            },
            error: (e) => {
                this.error = e;
                this.loading = false;
            },
        });
    }
}
