import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DurationEditorComponent } from "./duration-editor/duration-editor.component";
import { ValuesEqualValidatorDirective } from "./values-equal-validator.directive";

@NgModule({
    declarations: [
        DurationEditorComponent,
        ValuesEqualValidatorDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    exports: [
        DurationEditorComponent,
        ValuesEqualValidatorDirective,
    ],
})
export class AppCommonUxModule {}
