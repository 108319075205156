import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { AppDialogModule } from "../dialog/dialog.module";
import { AppIconTextPairModule } from "../icon-text-pair/icon-text-pair.module";
import { AsyncButtonGroupComponent } from "./async-button-group.component";
import { MatButtonAsyncClickDirective } from "./button-async-click.directive";
import { MatButtonLoadingDirective } from "./button-loading.directive";
import { MatButtonMobileFriendlyDirective } from "./button-mobile-friendly.directive";

@NgModule({
    imports: [
        MatButtonModule,
        AppIconTextPairModule,
        AppDialogModule,
        MatButtonLoadingDirective,
    ],
    exports: [
        MatButtonModule,
        AppIconTextPairModule,
        MatButtonLoadingDirective,
        MatButtonAsyncClickDirective,
        AsyncButtonGroupComponent,
        MatButtonMobileFriendlyDirective,
    ],
    declarations: [
        MatButtonAsyncClickDirective,
        AsyncButtonGroupComponent,
        MatButtonMobileFriendlyDirective,
    ],
})
export class AppButtonModule {}
