import { formatLongDate } from "app/common/utils";
import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";
import { Performance } from "./performance";

export class Concert extends BreezeEntity {
    public id!: number;
    public coverPhotoBlobId!: string | null;
    public occasion!: string;
    public location!: string | null;
    public date!: Date;
    public notes!: string | null;

    public performance!: Performance[];

    public get formattedOccasion() {
        let formattedConcert = "";

        formattedConcert += this.occasion;

        if (this.location) {
            formattedConcert += " at " + this.location;
        }

        const formattedDate = formatLongDate(this.date);
        formattedConcert += " on " + formattedDate;

        return formattedConcert;
    }
}

export const ConcertBreezeModel: BreezeModel<Concert> = {
    type: Concert,
    typeName: "Concert",
};
