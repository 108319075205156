import {
    ComponentRef,
    Directive,
    Input,
    OnChanges,
    Optional,
    Renderer2,
    SimpleChanges,
    ViewContainerRef,
} from "@angular/core";
import { MatButton, MatFabButton, MatIconButton } from "@angular/material/button";
import { ThemePalette } from "@angular/material/core";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading],
               button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading],
               button[mat-flat-button][loading]`,
})
export class MatButtonLoadingDirective implements OnChanges {
    private spinner?: ComponentRef<MatProgressSpinner>;
    protected matButton: MatButton | MatIconButton | MatFabButton;

    @Input() public loading = false;
    @Input() public disabled = false;

    @Input()
    color: ThemePalette;

    constructor(
        @Optional() matButton: MatButton | null,
        @Optional() matIconButton: MatIconButton | null,
        @Optional() matFabButton: MatFabButton | null,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {
        if (matButton) {
            this.matButton = matButton;
        } else if (matIconButton) {
            this.matButton = matIconButton;
        } else if (matFabButton) {
            this.matButton = matFabButton;
        } else {
            throw new Error("MatButtonLoadingDirective must be placed on a matButton element");
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.loading) {
            return;
        }

        if (changes.loading.currentValue) {
            this.matButton._elementRef.nativeElement.classList.add("mat-loading");
            this.matButton.disabled = true;
            this.createSpinner();
        } else if (!changes.loading.firstChange) {
            this.matButton._elementRef.nativeElement.classList.remove("mat-loading");
            this.matButton.disabled = this.disabled;
            this.destroySpinner();
        }
    }

    private createSpinner(): void {
        if (this.spinner) {
            return;
        }

        this.spinner = this.viewContainerRef.createComponent(MatProgressSpinner);
        this.spinner.instance.color = this.color;
        this.spinner.instance.diameter = 20;
        this.spinner.instance.mode = "indeterminate";
        this.renderer.appendChild(
            this.matButton._elementRef.nativeElement,
            this.spinner.instance._elementRef.nativeElement,
        );
    }

    private destroySpinner(): void {
        if (!this.spinner) {
            return;
        }

        this.spinner.destroy();
        delete this.spinner;
    }
}
