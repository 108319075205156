import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";

export class MemberBillingConfig extends BreezeEntity {
    public id!: number;
    public isEnabled!: boolean;
    public associationPeriodFeeDollars!: number;
    public concessionRate!: number;
    public earlyBirdDiscountDollars!: number;
    public earlyBirdDiscountDaysValid!: number;
    public notificationContactName!: string;
    public notificationContactEmail!: string;
    public bankName!: string | null;
    public bankAccountName!: string | null;
    public bankBsb!: string;
    public bankAccountNo!: string;
    public paymentInstructions!: string | null;
    public invoicePrefix!: string | null;
    public nextInvoiceNumber!: number;
}

export const MemberBillingConfigBreezeModel: BreezeModel<MemberBillingConfig> = {
    typeName: "MemberBillingConfig",
    type: MemberBillingConfig,
};
