import { BreezeEntity } from "app/dal/breeze-entity";
import { BreezeModel } from "app/dal/breeze-model";
import { AssetLoan } from "./asset-loan";

export class Asset extends BreezeEntity {
    id!: number;
    description!: string;
    quantity!: number;
    serialNo!: string | null;
    dateAcquired!: Date | null;
    valuePaid!: number | null;
    dateDiscarded!: Date | null;
    valueDiscarded!: number | null;
    insuranceValue!: number | null;
    currentLocation!: string | null;
    notes!: string | null;
    assetLoan!: AssetLoan[];

    public toString() {
        let formattedAsset = "";
        formattedAsset += this.description;

        if (this.serialNo) {
            formattedAsset += " with serial " + this.serialNo;
        }

        return formattedAsset;
    }
}

export const AssetBreezeModel: BreezeModel<Asset> = {
    typeName: "Asset",
    type: Asset,
};
