import { BreezeEntity } from "app/dal/breeze-entity";
import { BreezeModel } from "app/dal/breeze-model";
import { Member } from "./member";

export class AssetLoan extends BreezeEntity {
    id!: number;
    dateBorrowed!: Date;
    dateReturned!: Date | null;
    assetId!: number;
    memberId!: number;

    member!: Member | null;
}

export const AssetLoanBreezeModel: BreezeModel<AssetLoan> = {
    typeName: "AssetLoan",
    type: AssetLoan,
};
