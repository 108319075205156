import { ExistingProvider, InjectionToken, Type } from "@angular/core";

export interface DialogObserver {
    onDialogOpened(dialogName: string, inputData: unknown): void;
    onDialogClosed(dialogName: string, resolveData: unknown): void;
    onDialogCancelled(dialogName: string): void;
}

export const DIALOG_OBSERVERS = new InjectionToken<DialogObserver[]>("DIALOG_OBSERVER");

export function provideExistingDialogObserver(
    observerType: Type<DialogObserver>,
): ExistingProvider {
    return {
        provide: DIALOG_OBSERVERS,
        useExisting: observerType,
        multi: true,
    };
}
