import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export interface NotificationSnackbarData {
    icon: string;
    text: string;
}

@Component({
    template: `
        <mat-icon>{{ data.icon }}</mat-icon>
        {{ data.text }}
        <button
            mat-button
            color="accent"
            (click)="dismiss()"
        >
            Dismiss
        </button>
    `,
    styles: [
        `
            :host {
                display: flex;
                align-items: center;
            }

            mat-icon {
                margin-right: 0.5rem;
            }

            button {
                margin-left: auto;
            }
        `,
    ],
})
export class NotificationSnackbarComponent {
    public constructor(
        private snackbarRef: MatSnackBarRef<NotificationSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: NotificationSnackbarData,
    ) {}

    public dismiss() {
        this.snackbarRef.dismiss();
    }
}
