import { Component } from "@angular/core";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styles: [
        `
            .loading {
                position: absolute;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        `,
    ],
})
export class AppComponent {
    public isLoading = true;
}
