import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

export const loggedInGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    return authenticationService.isLoggedIn$.pipe(
        map((isLoggedIn) => {
            if (isLoggedIn) {
                return true;
            }

            return router.createUrlTree([
                "/login",
                {
                    redirect: state.url,
                },
            ]);
        }),
    );
};
