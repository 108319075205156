<img
    src="assets/orchestrate_logo.svg"
    alt="Orchestrate Logo"
/>
<p
    class="fail-text"
    *ngIf="resetState.isInvalid"
>
    Unknown error when requesting reset email
</p>
<p
    class="success-text"
    *ngIf="resetState.sentSuccessfully"
>
    Reset email sent successfully. Please check your inbox and follow the instructions there.
</p>
<form
    #resetForm="ngForm"
    (ngSubmit)="requestPasswordReset()"
>
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input
            matInput
            type="email"
            name="email"
            [(ngModel)]="email"
            [disabled]="resetState.isResetting"
            autocomplete="email"
            autofocus
        />
    </mat-form-field>

    <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="!resetForm.valid || resetState.isResetting"
        [loading]="resetState.isResetting"
    >
        Request Password Reset
    </button>
</form>
