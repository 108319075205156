import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IconTextPairComponent } from "./icon-text-pair.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [
        IconTextPairComponent,
    ],
    declarations: [
        IconTextPairComponent,
    ],
})
export class AppIconTextPairModule {}
