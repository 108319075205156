import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";
import { Concert } from "./concert";
import { Ensemble } from "./ensemble";
import { Member } from "./member";
import { Score } from "./score";

export class Performance extends BreezeEntity {
    public id!: number;
    public ensembleId!: number;
    public concertId!: number;

    public concert!: Concert | null;
    public ensemble!: Ensemble | null;
    public performanceMembers!: PerformanceMember[];
    public performanceScores!: PerformanceScore[];
}

export const PerformanceBreezeModel: BreezeModel<Performance> = {
    type: Performance,
    typeName: "Performance",
};

export class PerformanceMember extends BreezeEntity {
    public id!: number;
    public memberId!: number;
    public performanceId!: number;

    public member!: Member | null;
    public performance!: Performance | null;
}

export const PerformanceMemberBreezeModel: BreezeModel<PerformanceMember> = {
    type: PerformanceMember,
    typeName: "PerformanceMember",
};

export class PerformanceScore extends BreezeEntity {
    public id!: number;
    public performanceId!: number;
    public scoreId!: number;

    public performance!: Performance | null;
    public score!: Score | null;
}

export const PerformanceScoreBreezeModel: BreezeModel<PerformanceScore> = {
    type: PerformanceScore,
    typeName: "PerformanceScore",
};
