import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";
import { EnsembleMembership } from "./ensemble-membership";
import { Performance } from "./performance";
import { Role } from "./role";

export enum EnsembleStatus {
    Active = "Active",
    Disbanded = "Disbanded",
}

export class Ensemble extends BreezeEntity {
    id!: number;
    name!: string;
    status!: EnsembleStatus;
    memberBillingPeriodFeeDollars!: number;

    ensembleMembership!: EnsembleMembership[];
    ensembleRole!: EnsembleRole[];
    performance!: Performance[];

    public get isActive() {
        return this.status === EnsembleStatus.Active;
    }
}

export class EnsembleRole extends BreezeEntity {
    public id!: number;
    public ensembleId!: number;
    public roleId!: string;

    public ensemble!: Ensemble | null;
    public role!: Role | null;
}

export const EnsembleBreezeModel: BreezeModel<Ensemble> = {
    typeName: "Ensemble",
    type: Ensemble,
};

export const EnsembleMembershipBreezeModel: BreezeModel<EnsembleMembership> = {
    typeName: "EnsembleMembership",
    type: EnsembleMembership,
};

export const EnsembleRoleBreezeModel: BreezeModel<EnsembleRole> = {
    type: EnsembleRole,
    typeName: "EnsembleRole",
};
