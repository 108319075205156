<img
    src="assets/orchestrate_logo.svg"
    alt="Orchestrate Logo"
/>
<p class="intro-text">
    Welcome to Orchestrate, a software platform dedicated to supporting community music. To get
    started, please log in or register.
</p>
<p
    class="fail-text"
    *ngIf="loginState.isInvalid"
    [textContent]="loginState.failMessage"
></p>
<form (ngSubmit)="loginOnClick()">
    <mat-form-field [class.has-error]="loginState.isInvalid">
        <mat-label>Email</mat-label>
        <input
            matInput
            type="text"
            name="username"
            [(ngModel)]="username"
            [disabled]="loginState.isLoggingIn"
            autocomplete="email"
            autofocus
        />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Password</mat-label>
        <input
            matInput
            type="password"
            name="password"
            [(ngModel)]="password"
            [disabled]="loginState.isLoggingIn"
            autocomplete="current-password"
        />
    </mat-form-field>

    <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="loginState.isLoggingIn"
        [loading]="loginState.isLoggingIn"
    >
        Login
    </button>

    <a
        class="forgot-password"
        routerLink="/forgot-password"
        >Forgot your password?</a
    >

    <mat-divider></mat-divider>

    <p>Not using Orchestrate yet?</p>

    <a
        mat-stroked-button
        routerLink="/register"
        color="accent"
    >
        Register here
    </a>
</form>
