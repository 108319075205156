import { Role, RoleDto } from "app/auth/role";
import { RoleName } from "app/auth/role.enum";
import { TenantDto } from "../tenant/module/tenant-dto";
import { FeaturePermission } from "./feature-permission";

export interface UserDto {
    id: string;
    username: string;
    displayName: string;
    isGlobalAdmin: boolean;
    tenantRoles: TenantRolesDto[];
}

export interface TenantRolesDto extends TenantDto {
    roles: RoleDto[];
}

export class User {
    public id: string;
    public username: string;
    public displayName: string;
    public isGlobalAdmin: boolean;
    public tenantAndRoles: {
        tenant: TenantDto;
        roles: Roles;
    }[];

    public constructor(user: UserDto) {
        this.id = user.id;
        this.username = user.username;
        this.displayName = user.displayName;
        this.isGlobalAdmin = user.isGlobalAdmin;
        this.tenantAndRoles = user.tenantRoles.map((tr) => ({
            tenant: tr,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            roles: new Roles(tr.roles),
        }));
    }

    public getAccessibleTenants() {
        return this.tenantAndRoles.map((t) => t.tenant);
    }

    public getAccessibleTenantByAbbreviation(abbreviation: string) {
        return this.tenantAndRoles.find((e) => e.tenant.abbreviation === abbreviation)?.tenant;
    }

    public getImpliedTenantRolesByAbbreviation(abbreviation: string) {
        // Ensure global admin first to match ClaimsPrincipalExtensions logic
        if (this.isGlobalAdmin) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return new Roles([new Role({ id: "", name: RoleName.Admin })]);
        }

        const tr = this.tenantAndRoles.find((e) => e.tenant.abbreviation === abbreviation);
        if (tr) {
            return tr.roles;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return new Roles([]);
        }
    }
}

export class Roles {
    roles: Role[];

    public constructor(roles: RoleDto[]) {
        this.roles = roles.map((r) => new Role(r));
    }

    public hasPermission(permission: FeaturePermission) {
        return this.roles.some((r) => {
            return r.permissions.includes(permission);
        });
    }
}
