// Keep in sync with FeaturePermission.cs
export enum FeaturePermission {
    None = "None",

    PlatformAccess = "PlatformAccess",
    PlatformSecurityAccess = "PlatformSecurityAccess",

    SettingsWrite = "SettingsWrite",

    AssetRead = "AssetRead",
    AssetWrite = "AssetWrite",

    ScoreRead = "ScoreRead",
    ScoreWrite = "ScoreWrite",

    MemberRead = "MemberRead",
    MemberWrite = "MemberWrite",
    MemberDetailRead = "MemberDetailRead",
    MemberDetailWrite = "MemberDetailWrite",

    MemberBillingRead = "MemberBillingRead",
    MemberBillingWrite = "MemberBillingWrite",

    EnsembleRead = "EnsembleRead",
    EnsembleWrite = "EnsembleWrite",

    ConcertRead = "ConcertRead",
    ConcertWrite = "ConcertWrite",

    ContactsRead = "ContactsRead",
    ContactsWrite = "ContactsWrite",

    MailingListRead = "MailingListRead",
    MailingListWrite = "MailingListWrite",
    MailingListGlobalSend = "MailingListGlobalSend",
}
