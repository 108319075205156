import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AppConfig } from "app/common/app-config";
import { lastValueFrom, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { User, UserDto } from "./user";
import { UserObserver, USER_OBSERVERS } from "./user-observer";

interface UserResponse {
    token: string;
    expiration: Date;
    user: UserDto;
}

const tokenKey = "band-manager:bearer-token";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    public readonly user$ = new ReplaySubject<User | undefined>(1);
    public readonly isLoggedIn$ = this.user$.pipe(map((user) => !!user));

    constructor(
        private appConfig: AppConfig,
        private http: HttpClient,
        @Inject(USER_OBSERVERS) private userObservers: UserObserver[],
    ) {}

    public get bearerToken() {
        return sessionStorage.getItem(tokenKey);
    }

    private get authEndpoint$() {
        return this.appConfig.serverEndpoint("security");
    }

    public async login(username: string, password: string) {
        const user = await this.performLogin(username, password);
        this.processLoggedInUser(user);
        return user;
    }

    private async performLogin(username: string, password: string): Promise<User> {
        const credentials = {
            username: username,
            password: password,
        };

        try {
            const response = await lastValueFrom(
                this.authEndpoint$.pipe(
                    switchMap((authEndpoint) =>
                        this.http.post<UserResponse>(`${authEndpoint}/login`, credentials),
                    ),
                ),
            );
            sessionStorage.setItem(tokenKey, response.token);
            return new User(response.user);
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status === 401) {
                return Promise.reject(new Error("Incorrect username or password"));
            } else {
                return Promise.reject(new Error("Could not connect to server"));
            }
        }
    }

    private processLoggedInUser(user: User | undefined) {
        this.user$.next(user);
        this.userObservers.forEach((o) => o.onUserChanged(user));

        return user;
    }

    public async checkForCurrentSession(): Promise<User | undefined> {
        try {
            if (!this.bearerToken) {
                this.processLoggedInUser(undefined);
                return;
            }

            const response = await lastValueFrom(
                this.authEndpoint$.pipe(
                    switchMap((authEndpoint) => this.http.get<UserDto>(`${authEndpoint}/user`)),
                ),
            );
            return this.processLoggedInUser(new User(response));
        } catch (e) {
            this.processLoggedInUser(undefined);
        }
    }

    public async logout() {
        try {
            await lastValueFrom(
                this.authEndpoint$.pipe(
                    switchMap((authEndpoint) =>
                        this.http.post(`${authEndpoint}/logout`, undefined),
                    ),
                ),
            );
            sessionStorage.removeItem(tokenKey);
        } finally {
            this.processLoggedInUser(undefined);
        }
    }

    public requestPasswordReset(email: string, redirect?: string) {
        return lastValueFrom(
            this.authEndpoint$.pipe(
                switchMap((authEndpoint) =>
                    this.http.post(`${authEndpoint}/request-password-reset`, { email, redirect }),
                ),
            ),
        );
    }

    public resetPassword(email: string, token: string, password: string) {
        return lastValueFrom(
            this.authEndpoint$.pipe(
                switchMap((authEndpoint) =>
                    this.http.post(`${authEndpoint}/reset-password`, { email, password, token }),
                ),
            ),
        );
    }
}
