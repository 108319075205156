<img
    src="assets/orchestrate_logo.svg"
    alt="Orchestrate Logo"
/>
<p *ngIf="!isInvalidLink">
    Set your password below and we'll get you logged in before you can say presto!
</p>
<p
    class="fail-text"
    *ngIf="resetState.isInvalid"
>
    {{ resetState.invalidReason }}
</p>
<p
    class="success-text"
    *ngIf="resetState.resetSuccessfully"
>
    Password reset successfully. Logging you in...
</p>
<form
    *ngIf="!isInvalidLink; else invalidLinkTemplate"
    #resetForm="ngForm"
    (ngSubmit)="resetPassword()"
>
    <mat-form-field [class.was-validated]="newPassword.touched && newPassword.dirty">
        <mat-label>New Password</mat-label>
        <input
            #newPassword="ngModel"
            matInput
            type="password"
            name="newPassword"
            placeholder="New Password"
            [(ngModel)]="model.newPassword"
            minlength="10"
            required
            autofocus
            autocomplete="new-password"
        />
        <mat-hint> Password must be at least 10 characters long </mat-hint>
    </mat-form-field>
    <mat-form-field [class.was-validated]="confirmPassword.touched && confirmPassword.dirty">
        <mat-label>Confirm Password</mat-label>
        <input
            #confirmPassword="ngModel"
            matInput
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            [(ngModel)]="model.confirmPassword"
            appValuesEqual="newPassword"
            minlength="10"
            required
            autocomplete="new-password"
        />
        <mat-error *ngIf="confirmPassword.errors?.valuesEqual"> Passwords do not match </mat-error>
    </mat-form-field>

    <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="!resetForm.valid || resetState.isResetting"
        [loading]="resetState.isResetting"
    >
        Set Password
    </button>
</form>
<ng-template #invalidLinkTemplate>
    <p
        *ngIf="!resetSent"
        class="fail-text"
    >
        It looks like this link has expired or is no longer valid. Please send another email and try
        again.
    </p>
    <button
        *ngIf="!resetSent; else resetSentTemplate"
        mat-raised-button
        color="primary"
        [disabled]="resetSending"
        [loading]="resetSending"
        (click)="sendResetLink()"
    >
        Resend email
    </button>
</ng-template>
<ng-template #resetSentTemplate>
    <p class="success-text">
        Email sent successfully. Please check your inbox and follow the instructions there.
    </p>
</ng-template>
