import { RoleName } from "app/auth/role.enum";
import { FeaturePermission } from "./feature-permission";

export const roleLabel: Record<RoleName, string> = {
    [RoleName.Admin]: "Administrator",
    [RoleName.BillingAdmin]: "Billing Administrator",
    [RoleName.CommitteeMember]: "Committee Member",
    [RoleName.Editor]: "Editor",
    [RoleName.Viewer]: "Viewer",
};

// TODO Fetch from backend instead of hard coding here to reduce duplication
// In meantime, keep in sync with StandardRole.cs
export const rolePermissions: Record<RoleName, FeaturePermission[]> = {
    [RoleName.Admin]: Object.values(FeaturePermission).filter((e) => e !== FeaturePermission.None),
    [RoleName.BillingAdmin]: [
        FeaturePermission.PlatformAccess,
        FeaturePermission.MemberRead,
        FeaturePermission.MemberBillingRead,
        FeaturePermission.MemberBillingWrite,
        FeaturePermission.EnsembleRead,
        FeaturePermission.EnsembleWrite,
    ],
    [RoleName.CommitteeMember]: [
        FeaturePermission.PlatformAccess,
        FeaturePermission.AssetRead,
        FeaturePermission.AssetWrite,

        FeaturePermission.ScoreRead,
        FeaturePermission.ScoreWrite,

        FeaturePermission.MemberRead,
        FeaturePermission.MemberWrite,
        FeaturePermission.MemberDetailRead,
        FeaturePermission.MemberDetailWrite,

        FeaturePermission.MemberBillingRead,

        FeaturePermission.EnsembleRead,
        FeaturePermission.EnsembleWrite,

        FeaturePermission.ConcertRead,
        FeaturePermission.ConcertWrite,

        FeaturePermission.ContactsRead,
        FeaturePermission.ContactsWrite,

        FeaturePermission.MailingListRead,
        FeaturePermission.MailingListWrite,
        FeaturePermission.MailingListGlobalSend,
    ],
    [RoleName.Editor]: [
        FeaturePermission.PlatformAccess,
        FeaturePermission.AssetRead,
        FeaturePermission.AssetWrite,

        FeaturePermission.ScoreRead,
        FeaturePermission.ScoreWrite,

        FeaturePermission.MemberRead,
        FeaturePermission.MemberWrite,
        FeaturePermission.MemberDetailRead,
        FeaturePermission.MemberDetailWrite,

        FeaturePermission.EnsembleRead,
        FeaturePermission.EnsembleWrite,

        FeaturePermission.ConcertRead,
        FeaturePermission.ConcertWrite,

        FeaturePermission.ContactsRead,
        FeaturePermission.ContactsWrite,
    ],
    [RoleName.Viewer]: [
        FeaturePermission.PlatformAccess,
        FeaturePermission.AssetRead,

        FeaturePermission.ScoreRead,

        FeaturePermission.MemberRead,

        FeaturePermission.EnsembleRead,

        FeaturePermission.ConcertRead,
    ],
};

export interface RoleDto {
    id: string;
    name: RoleName;
}

export class Role implements RoleDto {
    public id: string;
    public name: RoleName;

    public constructor(role: RoleDto) {
        this.id = role.id;
        this.name = role.name;
    }

    public get label() {
        return roleLabel[this.name];
    }

    public get permissions() {
        return rolePermissions[this.name];
    }
}
