import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { notLoggedInGuard } from "./not-logged-in-guard";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const authRoutes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        canActivate: [
            notLoggedInGuard,
        ],
        data: {
            title: "Login",
        },
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        canActivate: [
            notLoggedInGuard,
        ],
        data: {
            title: "Forgot password",
        },
    },
    {
        path: "set-password",
        component: ResetPasswordComponent,
        canActivate: [
            notLoggedInGuard,
        ],
        data: {
            title: "Set password",
        },
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(authRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppAuthRoutingModule {}
