import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";

export class Role extends BreezeEntity {
    id!: string;
    name!: string;
    // Other fields which we don't care about
}

export const RoleBreezeModel: BreezeModel<Role> = {
    type: Role,
    typeName: "OrchestrateRole",
};
