<div class="orchestrate-container">
    <img
        src="/assets/orchestrate_logo_wide.svg"
        alt="Orchestrate logo"
    />

    <div class="title">
        <h1>Select a group to view</h1>
        <app-user-dropdown>
            <a
                *ngIf="(user$ | async)?.isGlobalAdmin"
                mat-menu-item
                routerLink="/administration"
            >
                <mat-icon>settings_applications</mat-icon>
                Administration
            </a>
        </app-user-dropdown>
    </div>

    <h2 *ngIf="isGlobalAdmin$ | async">My groups</h2>
    <div
        *appLoadingUntilFirstEmit="let tenants of accessibleTenants$"
        class="my-groups-container"
    >
        <ul *ngIf="tenants.length > 0; else noTenantsTemplate">
            <li *ngFor="let wrapper of tenants">
                <a [routerLink]="[wrapper.tenant.abbreviation, 'home']">
                    <img
                        [src]="wrapper.image"
                        [alt]="wrapper.tenant.name"
                    />
                    <h3>{{ wrapper.tenant.name }}</h3>
                </a>
            </li>
        </ul>
        <ng-template #noTenantsTemplate>
            You don't have permission to view any organisations.
        </ng-template>
    </div>

    <ng-container *ngIf="isGlobalAdmin$ | async">
        <h2>All groups</h2>
        <ul *appLoadingUntilFirstEmit="let tenants of allTenants$">
            <li *ngFor="let wrapper of tenants">
                <a [routerLink]="[wrapper.tenant.abbreviation, 'home']">
                    <img
                        [src]="wrapper.image"
                        [alt]="wrapper.tenant.name"
                    />
                    <h3>{{ wrapper.tenant.name }}</h3>
                </a>
            </li>
        </ul>
    </ng-container>
</div>
