import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { ErrorResponse, ServerError } from "./error";

export class ValidationResultError<T> extends ServerError<ValidationFailureErrorResponse<T>> {
    public static matchingResponse<T>(
        response: ErrorResponse,
    ): response is ValidationFailureErrorResponse<T> {
        return response.type === "ValidationFailure";
    }

    public get validationResults() {
        return this.data.validationResults;
    }
}

export interface ValidationFailureErrorResponse<T> extends ErrorResponse {
    type: "ValidationFailure";
    validationResults: ValidationFailure<T>;
}

// Based off https://stackoverflow.com/a/50051970/17403538
type Primative = string | number | boolean | null | undefined;
export type ValidationFailure<T> = T extends Primative
    ? PropertyError[] | undefined
    : {
          [K in keyof T]?: ValidationFailure<T[K]>;
      };

export interface PropertyError {
    errorCode: string;
    errorMessage: string;
}

export function setValidationResultsOnFormGroup<TValidation extends Record<string, unknown>>(
    validationFailure: ValidationFailure<TValidation> | undefined,
    formGroup: FormGroup,
) {
    if (!validationFailure) {
        return;
    }

    const formControls = formGroup.controls;
    for (const p of Object.keys(formControls)) {
        const k = p as keyof typeof formGroup.controls;
        const k2 = p as keyof ValidationFailure<TValidation>;
        if (!validationFailure[k2]) {
            continue;
        }

        if (formControls[k] instanceof FormControl && Array.isArray(validationFailure[k2])) {
            const error = validationFailure[k2] as PropertyError[];
            setValidationResultOnFormControl(error, formControls[k]!);
        } else if (
            formControls[k] instanceof FormGroup &&
            typeof validationFailure[k2] === "object"
        ) {
            setValidationResultsOnFormGroup(validationFailure[k2], formControls[k] as any);
        } else {
            throw new Error(
                `Unknown or mismatching formControl (${k}:${typeof formControls[k]})` +
                    ` with validation (${String(k2)}:${typeof validationFailure[k2]})`,
            );
        }
    }
}

export function setValidationResultOnFormControl(
    validationResult: PropertyError[] | undefined,
    formControl: AbstractControl,
) {
    if (!validationResult) {
        return;
    }

    let counter = 1;
    const errors = validationResult.reduce<ValidationErrors>((errs, nextResult) => {
        // If there are multiple errors with the same code, ensure we have a unique name for
        // each of them
        const suffix = errs[nextResult.errorCode] ? String(counter++) : "";
        errs[`${nextResult.errorCode}${suffix}`] = nextResult.errorMessage;
        return errs;
    }, {});
    formControl.setErrors(errors);
}
