<div *ngIf="user$ | async as user">
    <button
        *ngIf="(isMobile$ | async) === false"
        mat-button
        class="toolbar-button"
        [matMenuTriggerFor]="menu"
    >
        <mat-icon class="desktop-account-icon">{{ accountIconOrDefault }}</mat-icon>
        {{ user.displayName }}
        <mat-icon class="chevron-icon">expand_more</mat-icon>
    </button>
    <button
        *ngIf="isMobile$ | async"
        mat-icon-button
        class="mobile-account-icon-button"
        [matMenuTriggerFor]="menu"
    >
        <mat-icon>{{ accountIconOrDefault }}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <div
            *ngIf="isMobile$ | async"
            mat-menu-item
            disabled
        >
            <mat-icon>account_circle</mat-icon>
            {{ user.displayName }}
        </div>
        <ng-content></ng-content>
        <button
            mat-menu-item
            (click)="logout($event)"
        >
            <mat-icon>logout</mat-icon>
            Logout
        </button>
    </mat-menu>
</div>
