import { BreezeEntity } from "app/dal/breeze-entity";
import { BreezeModel } from "app/dal/breeze-model";

export enum ScoreCategory {
    MusicLibrary,
    Other,
}

interface CategoryMetadata {
    category: ScoreCategory;
    label: string;
    singularText: string;
    pluralText: string;
    icon: string;
}
export const ScoreCategoryMetadata: { [category in ScoreCategory]: CategoryMetadata } = {
    [ScoreCategory.MusicLibrary]: {
        category: ScoreCategory.MusicLibrary,
        label: "In Library",
        icon: "library_add_check",
        singularText: "This piece has been verified to be in the music library",
        pluralText: "Music that is verified to be in the music library",
    },
    [ScoreCategory.Other]: {
        category: ScoreCategory.Other,
        label: "Other",
        icon: "quiz",
        singularText:
            "This piece has been played in the past but is not available in the music library",
        pluralText:
            "Music that has been played in the past but is not available in the music library",
    },
};
export const AllScoreCategoryMetadata = Object.values(ScoreCategoryMetadata);

export class Score extends BreezeEntity {
    id!: number;
    title!: string;
    composer!: string;
    arranger!: string;
    genre!: string;
    grade!: number;
    duration!: number;
    datePurchased!: Date | null;
    valuePaid!: number;
    location!: string;
    notes!: string;
    isOwned!: boolean;
    inLibrary!: boolean;

    attachments!: ScoreAttachment[];

    public toString() {
        return this.formattedName;
    }

    public get formattedName() {
        let formattedScore = "";

        formattedScore += this.title;

        if (this.composer) {
            formattedScore += " by " + this.composer;
        }

        if (this.arranger) {
            formattedScore += " arr. " + this.arranger;
        }

        return formattedScore;
    }

    public get formattedDuration() {
        const min = Math.floor(this.duration / 60);
        const sec = this.duration % 60;
        return `${min}min ${sec}sec`;
    }

    public get category() {
        return this.inLibrary ? ScoreCategory.MusicLibrary : ScoreCategory.Other;
    }

    public set category(value: ScoreCategory) {
        this.inLibrary = value === ScoreCategory.MusicLibrary;
    }
}

export class ScoreAttachment extends BreezeEntity {
    id!: number;
    scoreId!: number;
    blobId!: string;

    score?: Score;
}

export const ScoreBreezeModel: BreezeModel<Score> = {
    typeName: "Score",
    type: Score,
};

export const ScoreAttachmentBreezeModel: BreezeModel<ScoreAttachment> = {
    typeName: "ScoreAttachment",
    type: ScoreAttachment,
};
