import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AsyncIfDirective } from "./async-if.directive";
import { LoadingIfUndefinedDirective } from "./loading-if-undefined.directive";
import { LoadingUntilFirstEmitDirective } from "./loading-until-first-emit.directive";
import { LoadingComponent } from "./loading.component";

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        MatProgressSpinnerModule,
        LoadingComponent,
        AsyncIfDirective,
        LoadingIfUndefinedDirective,
        LoadingUntilFirstEmitDirective,
    ],
    declarations: [
        LoadingComponent,
        AsyncIfDirective,
        LoadingIfUndefinedDirective,
        LoadingUntilFirstEmitDirective,
    ],
})
export class AppLoadingModule {}
