import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, of, throwError } from "rxjs";
import { catchError, first, switchMap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // Only redirect if user is logged in as the route guard will otherwise redirect the user
                    this.authenticationService.isLoggedIn$
                        .pipe(
                            first(),
                            switchMap((isLoggedIn) => (isLoggedIn ? of(undefined) : EMPTY)),
                            switchMap(() => this.authenticationService.logout()),
                            switchMap(() =>
                                this.router.navigate([
                                    "/login",
                                    {
                                        failMessage: "Session has timed out, please log back in",
                                        redirect: this.router.url,
                                    },
                                ]),
                            ),
                        )
                        .subscribe();
                }

                return throwError(() => error);
            }),
        );
    }
}
