import { ExistingProvider, InjectionToken, Type } from "@angular/core";
import { User } from "app/auth/user";

export interface UserObserver {
    onUserChanged(user: User | undefined): void;
}

export const USER_OBSERVERS = new InjectionToken<UserObserver[]>("USER_OBSERVER");

export function provideExistingUserObserver(observerType: Type<UserObserver>): ExistingProvider {
    return {
        provide: USER_OBSERVERS,
        useExisting: observerType,
        multi: true,
    };
}
