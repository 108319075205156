import { Injectable } from "@angular/core";
import { defer, Observable } from "rxjs";
import { first, map, shareReplay } from "rxjs/operators";

export enum Environment {
    Production = "Production",
    Staging = "Staging",
    Development = "Development",
}

interface IAppConfig {
    environmentName: keyof typeof Environment;
    sentryDsn?: string;
    serverEndpoint: string;
    heapAppId?: string;
    hcaptchaSiteKey?: string;
}

@Injectable({
    providedIn: "root",
})
export class AppConfig {
    private _configValues$: Observable<IAppConfig>;

    public constructor() {
        // Don't use httpClient to avoid circular references
        this._configValues$ = defer(async () => {
            const response = await fetch("app-config.json");
            const json = await response.json();
            return json as IAppConfig;
        }).pipe(shareReplay(1));
    }

    public readonly maxUploadSizeBytes = 25 * 1024 * 1024;

    public get config$() {
        return this._configValues$.pipe(first());
    }

    public get environment$() {
        return this.getConfigValue((c) => c.environmentName).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            map((e) => Environment[e] ?? Environment.Development),
        );
    }

    public get sentryDsn$() {
        return this.getConfigValue((c) => c.sentryDsn);
    }

    public get serverEndpoint$() {
        return this.getConfigValue((c) => c.serverEndpoint);
    }

    public get heapAppId$() {
        return this.getConfigValue((c) => c.heapAppId);
    }

    public get hcaptchaSiteKey$() {
        return this.getConfigValue((c) => c.hcaptchaSiteKey);
    }

    public serverEndpoint(path: string) {
        return this.serverEndpoint$.pipe(map((serverEndpoint) => `${serverEndpoint}/${path}`));
    }

    private getConfigValue<T>(getter: (configValues: IAppConfig) => T) {
        return this.config$.pipe(map(getter));
    }
}
