import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "app/common/app-config";
import { voidFunction } from "app/common/utils";
import { BreezeEntity } from "app/dal/breeze-entity";
import { BreezeModel } from "app/dal/breeze-model";
import {
    config,
    DataService,
    EntityManager,
    EntityQuery,
    MetadataStore,
    NamingConvention,
    Predicate,
    SaveOptions,
    Validator,
} from "breeze-client";
import { AjaxHttpClientAdapter } from "breeze-client/adapter-ajax-httpclient";
import { DataServiceWebApiAdapter } from "breeze-client/adapter-data-service-webapi";
import { ModelLibraryBackingStoreAdapter } from "breeze-client/adapter-model-library-backing-store";
import { UriBuilderJsonAdapter } from "breeze-client/adapter-uri-builder-json";
import { environment } from "environments/environment";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { filter, first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import rawMetadata from "./breeze-metadata.json";
import { breezeModelList } from "./breeze-model-list";
import { fromBreezeEvent } from "./breeze-rxjs-utilities";
import { customValidatorFactories, customValidators } from "./custom-validators";

for (const validator of customValidators) {
    Validator.register(validator);
}
for (const factory of customValidatorFactories) {
    Validator.registerFactory(factory.build, factory.name);
}

@Injectable({
    providedIn: "root",
})
export class BreezeService {
    private readonly _entityManager$: Observable<EntityManager>;
    private readonly _isInitialised$ = new BehaviorSubject(false);

    public constructor(
        private appConfig: AppConfig,
        httpClient: HttpClient,
    ) {
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(httpClient);
        DataServiceWebApiAdapter.register();
        NamingConvention.camelCase.setAsDefault();
        config.noEval = true;

        this._entityManager$ = appConfig.serverEndpoint$.pipe(
            switchMap(async (serverEndpoint) => {
                const useLocalMetadata = environment.production;
                const dataService = new DataService({
                    serviceName: `${serverEndpoint}/breeze`,
                    hasServerMetadata: !useLocalMetadata,
                });

                const metadataStore = new MetadataStore();
                if (useLocalMetadata) {
                    metadataStore.importMetadata(rawMetadata);
                } else {
                    await metadataStore.fetchMetadata(dataService);
                }

                for (const model of breezeModelList) {
                    metadataStore.registerEntityTypeCtor(model.typeName, model.type);

                    for (const entry of Object.entries(model.additionalValidators ?? {})) {
                        const type = metadataStore.getAsEntityType(model.typeName);
                        type.getProperty(entry[0]).validators.push(...entry[1]);
                    }
                }

                const entityManager = new EntityManager({
                    dataService,
                    metadataStore,
                });
                return entityManager;
            }),
            tap(() => this._isInitialised$.next(true)),
            shareReplay(1),
        );

        // Clear all server errors when there is a change to an entity, we have no idea
        // if the changes will fix the error or not, have to allow a save to see!
        this.initialisedEntityManager$
            .pipe(switchMap((em) => fromBreezeEvent(em.entityChanged)))
            .subscribe((data) => {
                if (data.entity) {
                    data.entity.entityAspect
                        .getValidationErrors()
                        .filter((e) => e.isServerError)
                        .forEach((e) => data.entity?.entityAspect.removeValidationError(e));
                }
            });
    }

    /** Get the current entity manager, only after there has been activity
     * that causes it to be initialised. */
    public get initialisedEntityManager$() {
        return this._isInitialised$.pipe(
            filter((isInitialised) => isInitialised),
            switchMap(() => this.entityManager$),
        );
    }

    /** Get the current entity manager, initialising it if it hasn't been done yet */
    public get entityManager$() {
        return this._entityManager$.pipe(first());
    }

    public create<T extends BreezeEntity>(model: BreezeModel<T>, initialValues: Partial<T>) {
        return this.entityManager$.pipe(
            map((entityManager) => entityManager.createEntity(model.typeName, initialValues) as T),
        );
    }

    public getById<T extends BreezeEntity>(model: BreezeModel<T>, id: number) {
        return this.entityManager$.pipe(
            switchMap((entityManager) => entityManager.fetchEntityByKey(model.typeName, id)),
            map((result) => result.entity as T | undefined),
        );
    }

    public getAll<T extends BreezeEntity>(model: BreezeModel<T>) {
        const query = new EntityQuery(model.typeName);
        return this.executeQuery<T>(query);
    }

    public getByPredicate<T extends BreezeEntity>(model: BreezeModel<T>, predicate: Predicate) {
        const query = new EntityQuery(model.typeName).where(predicate);
        return this.executeQuery<T>(query);
    }

    public getCountByPredicate<T extends BreezeEntity>(
        model: BreezeModel<T>,
        predicate: Predicate,
    ) {
        const query = new EntityQuery(model.typeName).where(predicate).take(0).inlineCount(true);
        return this.entityManager$.pipe(
            switchMap((entityManager) => entityManager.executeQuery(query)),
            map((r) => r.inlineCount ?? 0),
        );
    }

    public createQuery<T extends BreezeEntity>(model: BreezeModel<T>) {
        return new EntityQuery(model.typeName);
    }

    public executeQuery<T extends BreezeEntity>(query: EntityQuery) {
        return this.entityManager$.pipe(
            switchMap((entityManager) => entityManager.executeQuery(query)),
            map((queryResults) => queryResults.results as T[]),
        );
    }

    public delete(entity: BreezeEntity) {
        entity.entityAspect.setDeleted();
    }

    public cancelChanges(...entities: BreezeEntity[]) {
        return this.entityManager$.pipe(
            map((entityManager) => {
                for (const entity of entities) {
                    entity.entityAspect.rejectChanges();
                }

                if (entities.length === 0) {
                    entityManager.rejectChanges();
                }
            }),
        );
    }

    public saveChanges(...entities: BreezeEntity[]) {
        entities = entities.filter((e) => !e.entityAspect.entityState.isUnchanged());
        return this.entityManager$.pipe(
            switchMap((entityManager) => {
                return entities.length > 0
                    ? entityManager.saveChanges(entities)
                    : entityManager.saveChanges();
            }),
            map(voidFunction),
        );
    }

    public customSave(
        location: { controller?: string; resource: string },
        ...entities: BreezeEntity[]
    ) {
        const dataService$ = location.controller
            ? this.appConfig.serverEndpoint(location.controller).pipe(
                  map(
                      (controllerUri) =>
                          new DataService({
                              serviceName: controllerUri,
                              hasServerMetadata: false,
                          }),
                  ),
              )
            : of(undefined);

        return forkJoin([this.entityManager$, dataService$]).pipe(
            switchMap(([entityManager, dataService]) => {
                return entityManager.saveChanges(
                    entities,
                    new SaveOptions({
                        dataService,
                        resourceName: location.resource,
                    }),
                );
            }),
            map(voidFunction),
        );
    }

    public getChanges(): Observable<BreezeEntity[]> {
        return this.entityManager$.pipe(map((em) => em.getChanges()));
    }
}
