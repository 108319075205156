import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";

export enum MemberBillingPeriodState {
    Created = "Created",
    Draft = "Draft",
    Open = "Open",
    Closed = "Closed",
}

const stateOrder = [
    MemberBillingPeriodState.Created,
    MemberBillingPeriodState.Draft,
    MemberBillingPeriodState.Open,
    MemberBillingPeriodState.Closed,
] as const;

export function billingPeriodStateCompare(
    lhs: MemberBillingPeriodState,
    rhs: MemberBillingPeriodState,
) {
    const lhsIndex = stateOrder.indexOf(lhs);
    const rhsIndex = stateOrder.indexOf(rhs);
    return lhsIndex - rhsIndex;
}

export class MemberBillingPeriod extends BreezeEntity {
    id!: number;
    name!: string;
    state!: MemberBillingPeriodState;
    earlyBirdDue!: Date | null;
    due!: Date;
    generated!: Date | null;
    sent!: Date | null;
    closed!: Date | null;
    notificationContactNotified!: Date | null;

    public get isAcceptingPayments() {
        return this.state === MemberBillingPeriodState.Open;
    }
}

export const MemberBillingPeriodBreezeModel: BreezeModel<MemberBillingPeriod> = {
    typeName: "MemberBillingPeriod",
    type: MemberBillingPeriod,
};
