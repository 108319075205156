import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    Observable,
} from "rxjs";
import { isLeafRoute, isOnPrimaryRouteTree } from "./common/routing-utils";
import { cacheLatest } from "./common/rxjs-utilities";

@Injectable({
    providedIn: "root",
})
export class AppService {
    public readonly currentRoute$: Observable<ActivatedRouteSnapshot>;
    public readonly pageTitle$: Observable<string | undefined>;

    private pageTitleOverride$ = new BehaviorSubject<string | undefined>(undefined);

    public constructor(router: Router) {
        this.currentRoute$ = router.events.pipe(
            filter((e): e is ActivationEnd => e instanceof ActivationEnd),
            filter((e) => isOnPrimaryRouteTree(e.snapshot)),
            // Ensure we only process the leaf route events (as this will go recursively go up
            // the route chain when multiple routes are activated at once)
            filter((e) => isLeafRoute(e.snapshot)),
            map((e) => e.snapshot),
            cacheLatest(),
        );

        // TODO Move to Angular 15 inbuilt handling
        // See https://angular.io/guide/router#setting-the-page-title
        const routeTitle$ = this.currentRoute$.pipe(
            map((route) => {
                let currentRoute: ActivatedRouteSnapshot | null = route;

                do {
                    if (typeof currentRoute.data.title === "string") {
                        return currentRoute.data.title;
                    } else {
                        currentRoute = currentRoute.parent;
                    }
                } while (currentRoute);

                return undefined;
            }),
            distinctUntilChanged(),
        );
        this.pageTitle$ = combineLatest([routeTitle$, this.pageTitleOverride$]).pipe(
            map(([routeTitle, pageTitleOverride]) => pageTitleOverride ?? routeTitle),
            distinctUntilChanged(),
            cacheLatest(),
        );
    }

    public setPageTitleOverride(title: string | undefined) {
        this.pageTitleOverride$.next(title);
    }
}
