import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const appRoutes: Routes = [
    {
        path: "register",
        loadChildren: () => import("app/register/register.module").then((m) => m.AppRegisterModule),
    },
    {
        path: "administration",
        loadChildren: () => import("app/admin/admin-routes").then((m) => m.adminRoutes),
    },
    {
        path: "",
        redirectTo: "/login",
        pathMatch: "full",
    },
    {
        path: "**",
        redirectTo: "/login",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {}
