import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { AppLoadingModule } from "app/common-ux/loading/loading.module";
import { UserDropdownComponent } from "app/common-ux/user-dropdown/user-dropdown.component";
import { BreezeService } from "app/dal/breeze.service";
import { switchMap } from "rxjs";
import { SelectTenantPageComponent } from "./select-tenant-page/select-tenant-page.component";
import { TenantAuthorisationService } from "./tenant-authorisation.service";
import { TenantIdInterceptor } from "./tenant-id.interceptor";
import { AppTenantRoutingModule } from "./tenant-routing.module";
import { TenantService } from "./tenant.service";

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        AppTenantRoutingModule,
        AppLoadingModule,
        UserDropdownComponent,
    ],
    declarations: [
        SelectTenantPageComponent,
    ],
    providers: [
        TenantService,
        TenantAuthorisationService,
        // We only want this in the Tenant module, but HTTP_INTERCEPTORS cannot be declared
        // in lazy loaded modules. So we'll make this an eager loaded module (most of the time
        // it will be needed pretty quickly anyway), and shell will be lazy loaded.
        // There isn't too much code in this module so the main bundle size shouldn't change
        // meaningfully.
        // See https://github.com/angular/angular/issues/36974
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TenantIdInterceptor,
            multi: true,
        },
    ],
})
export class AppTenantModule {
    public constructor(tenantService: TenantService, breezeService: BreezeService) {
        // Clear the breeze cache on a tenant switch
        tenantService.currentTenant$
            .pipe(switchMap(() => breezeService.initialisedEntityManager$))
            .subscribe((em) => {
                em.clear();
            });
    }
}
