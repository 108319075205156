import { BreezeEntity } from "../breeze-entity";
import { BreezeModel } from "../breeze-model";

export class Tenant extends BreezeEntity {
    public id!: string;
    public name!: string;
    public abbreviation!: string;
    public timeZone!: string;
    public website!: string | null;
    public contactAddress!: string;
    public logo!: string | null;
    public dateFounded!: Date | null;
    public dateRegistered!: Date;
}

export const TenantBreezeModel: BreezeModel<Tenant> = {
    typeName: "tenant",
    type: Tenant,
};
