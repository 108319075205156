import { BreezeEvent } from "breeze-client";
import { Observable } from "rxjs";

export function fromBreezeEvent<T>(breezeEvent: BreezeEvent<T>) {
    return new Observable<T>((subscriber) => {
        const handle = breezeEvent.subscribe((data) => {
            subscriber.next(data);
        });
        return () => breezeEvent.unsubscribe(handle);
    });
}
