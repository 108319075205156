import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AppService } from "app/app.service";
import { AppConfig } from "app/common/app-config";
import {
    distinctUntilChanged,
    first,
    map,
    Observable,
    of,
    Subscription,
    switchMap,
    tap,
} from "rxjs";
import { AuthenticationService } from "../../auth/authentication.service";
import { User } from "../../auth/user";
import { cacheLatest, filterForDefinedValue } from "../../common/rxjs-utilities";
import { TenantDto } from "./tenant-dto";
import { SKIP_INJECTED_TENANT_ID } from "./tenant-id.interceptor";
import { tenantAbbreviationParam } from "./tenant-routing.module";

@Injectable()
export class TenantService implements OnDestroy {
    private _tenant$: Observable<TenantDto | undefined>;
    private _isOnTenantedRoute = false;
    private tenantCache = new Map<string, TenantDto | undefined>();
    private subscription: Subscription;

    public constructor(
        private httpClient: HttpClient,
        private router: Router,
        private appConfig: AppConfig,
        appService: AppService,
        private authenticationService: AuthenticationService,
    ) {
        const tenantAbbreviation$ = appService.currentRoute$.pipe(
            map((r) => this.getTenantAbbreviationForRoute(r)),
            distinctUntilChanged(),
            cacheLatest(),
        );
        this.subscription = tenantAbbreviation$.subscribe((tenantAbbreviation) => {
            this._isOnTenantedRoute = !!tenantAbbreviation;
        });

        this._tenant$ = tenantAbbreviation$.pipe(
            switchMap((abbreviation) => {
                return abbreviation ? this.getTenantByAbbreviation(abbreviation) : of(undefined);
            }),
        );
    }

    public get isOnTenantedRoute(): boolean {
        return this._isOnTenantedRoute;
    }

    public get currentTenant$(): Observable<TenantDto | undefined> {
        return this._tenant$;
    }

    public get currentTenantId$() {
        return this.currentTenant$.pipe(map((t) => t?.tenantId));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public forceTenantRefresh() {
        return this.currentTenant$.pipe(
            first(),
            filterForDefinedValue(),
            tap((t) => this.tenantCache.delete(t.abbreviation)),
            switchMap(() => this.authenticationService.checkForCurrentSession()),
        );
    }

    public getAllTenants(): Observable<TenantDto[]> {
        return this.tenantAgnosticRequest<TenantDto[]>(`tenant`).pipe(
            tap((tenants) => {
                for (const tenant of tenants) {
                    this.tenantCache.set(tenant.abbreviation, tenant);
                }
            }),
        );
    }

    public getTenantByAbbreviation(abbreviation: string): Observable<TenantDto | undefined> {
        return this.authenticationService.user$.pipe(
            switchMap((user) => {
                if (!user) {
                    return of(undefined);
                }

                const tenant = user.getAccessibleTenantByAbbreviation(abbreviation);
                if (tenant) {
                    return of(tenant);
                } else if (user.isGlobalAdmin) {
                    if (this.tenantCache.has(abbreviation)) {
                        return of(this.tenantCache.get(abbreviation));
                    } else {
                        return this.tenantAgnosticRequest<TenantDto | undefined>(
                            `tenant/${abbreviation}`,
                        ).pipe(tap((t) => this.tenantCache.set(abbreviation, t)));
                    }
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    private tenantAgnosticRequest<T>(path: string) {
        return this.appConfig.serverEndpoint(path).pipe(
            switchMap((endpoint) =>
                this.httpClient.get<T>(endpoint, {
                    context: new HttpContext().set(SKIP_INJECTED_TENANT_ID, true),
                }),
            ),
        );
    }

    public getTenantAbbreviationForRoute(route: ActivatedRouteSnapshot | null) {
        while (route) {
            const abbreviation = route.paramMap.get(tenantAbbreviationParam);
            if (abbreviation) {
                return abbreviation;
            }

            route = route.parent;
        }
    }

    public getDefaultUrlForUser(user: User) {
        const tenants = user.getAccessibleTenants();
        if (tenants.length > 1) {
            return this.router.createUrlTree(["/group"]);
        } else if (tenants.length === 1) {
            return this.router.createUrlTree(["/group", tenants[0]!.abbreviation, "home"]);
        } else if (user.isGlobalAdmin) {
            // TODO Go to admin area
            return this.router.createUrlTree(["/group"]);
        } else {
            // TODO No access page
            return this.router.createUrlTree(["/group"]);
        }
    }
}
