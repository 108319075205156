import { Component } from "@angular/core";

@Component({
    selector: "app-loading",
    template: `<mat-spinner></mat-spinner>`,
    styles: [
        `
            :host {
                display: flex;
                justify-content: space-around;
            }
        `,
    ],
})
export class LoadingComponent {}
