import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonLoadingDirective } from "../button/button-loading.directive";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";
import { DialogService } from "./dialog.service";
import { NotificationSnackbarComponent } from "./notification-snackbar.component";

@NgModule({
    imports: [
        MatDialogModule,
        MatIconModule,
        MatSnackBarModule,
        MatButtonModule,
        MatButtonLoadingDirective,
        ConfirmationDialogComponent,
    ],
    exports: [
        MatDialogModule,
    ],
    declarations: [
        NotificationSnackbarComponent,
    ],
    providers: [
        DialogService,
    ],
})
export class AppDialogModule {}
